.App {
  /* background-image: linear-gradient(to right bottom, #e3c9d6, #eccccf, #efd2c8, #ecd9c6, #e4e1c9); */
  cursor: url('https://blurry.s3.fr-par.scw.cloud/blurry.png') 50 50, auto;
  /* background-image: linear-gradient(to bottom, #f6e5de, #faebea, #faf1f4, #fbf8fb, #ffffff); */
  padding: 0px !important
}

.aboutBg {
  background-color: #233931;
}

.bioTitle {
  font-family: 'Bodoni Moda';
  font-weight: 500;
}

.lang {
  font-family: 'Ysabeau SC';
  font-weight: 300;
}

.hobbie {
  font-family: 'Roboto';
  font-weight: 800;
}

.bioSub {
  font-family: 'Ysabeau SC';
  font-weight: 200;
}

.bioDesc {
  font-family: 'Roboto';
  font-weight: 300;
  text-align: justify;
}

.mockupIphone {
  height: auto;
  width: 80%;
}

.mockupMac {
  height: auto;
  width: 100%;
}

.dlIcon {
  width: 30px;
  height: 30px;
}

.mockupIphone:hover {
  /* transform: scale(1.5);
  z-index: 100!important; */
}

.slideInRow {
  animation: slide-up 0.85s cubic-bezier(0.65, 0, 0.35, 1) both
} 

@keyframes slide-up {
  from {
    transform: translateY(100px)
  }

  to {
    transform: translateY(0)
  }
}

.slide-left {
  animation: 3s slide-left;
}
@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}

.role {
  font-family: 'Ysabeau SC';
  font-weight: 100;
}

.desc {
  font-family: 'Roboto';
  font-weight: 200;
  font-size: large;
}

.copyright {
  font-family: 'Roboto';
  font-weight: 200;
}

.projectMenu {
  font-family: 'Bodoni Moda';
  font-weight: 600;
}

.projectMenu:hover {
  font-family: 'Bodoni Moda';
  font-weight: 600;
  font-size: large;
  color: #4a7355;
}

.projectMenuActive {
  font-family: 'Bodoni Moda';
  font-weight: 600;
  color: #4a7355;
}

.avatar {
  width: 200px;
  height: 200px;
  object-fit:cover;
}

.menuMain {
  position: fixed !important;
  top: 20px;
  right: 20px;
  z-index: 10 !important;
}

.nav-link {
  color: black !important;
  font-family: 'Roboto';
  /* font-family: 'Bodoni Moda'; */
  font-weight: 300;
}

.nav-link:hover {
  color: #4F8073 !important;
}

.navbar-expand-lg .navbar-nav {
  flex-direction: column !important;
  align-items: flex-end !important;
}

.menuItem {
  color: white !important;
  /* mix-blend-mode: difference !important; */
}


.nav-link {
  /* padding: 0px !important; */
  display: block;
  position: relative;
  padding: 0.2em 0;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: #c35b67;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.nav-link:hover::after,
.nav-link:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}


/* Scale from center */
li:nth-child(3) a::after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

.nav-link:nth-child(3) a:hover::after,
.nav-link:nth-child(3) a:focus::after{
  transform: scale(1);
}

.navbar {
  border-radius: 30px;
  background-color: hsla(158, 23%, 18%, 0) !important;
  /* background-color: hsla(158, 23%, 18%, .7) !important; */
  z-index: 10 !important;
  mix-blend-mode: difference;
}

.title {
  font-family: 'Bodoni Moda';
  font-weight: 900;
}

.subTitle {
  font-family: 'Bodoni Moda';
  font-weight: 500;
}

.sub {
  font-family: 'Ysabeau SC';
  font-weight: 200;
  color: #EFEFEF;
}

.mini {
  font-family: 'Roboto';
  font-weight: 200;
  font-size: small;
  margin-left: 10px;
}

.slideImg {
  height: 90vh;
  width: 100%;
  object-fit: cover;
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid transparent; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .35em; /* Adjust as needed */
  animation: 
    typing 8.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

.skills {
  flex-wrap: wrap;
}
.skillBg {
  background-color: #608E69 !important;
}

.flag{
  width: 40px;
  height: 40px;
  margin-right: 20px;

}

.footer {
  /* height: 200px; */
  background-color: black;
}

.iconSocial {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.footerLink {
  width: 50px;
  height: 50px;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: transparent; }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 57%}
  50%{background-position:100% 44%}
  100%{background-position:0% 57%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 57%}
  50%{background-position:100% 44%}
  100%{background-position:0% 57%}
}
@-o-keyframes AnimationName {
  0%{background-position:0% 57%}
  50%{background-position:100% 44%}
  100%{background-position:0% 57%}
}
@keyframes AnimationName {
  0%{background-position:0% 57%}
  50%{background-position:100% 44%}
  100%{background-position:0% 57%}
}

/* Timeline style */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  --color: rgba(30, 30, 30);
  /* --bgColor: #233931; */
  --bgColor: rgba(245, 245, 245);
  min-height: 100vh;
  display: grid;
  align-content: center;
  gap: 2rem;
}

h1 {
  text-align: center;
}

ul {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: min(60rem, 90%);
  margin-inline: auto;
}

/* line */
ul::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
ul li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
ul li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

/* date */
ul li .date {
  --dateH: 2.5rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);

  text-align: center;
  background-color: var(--accent-color);

  color: white;
  font-size: 1.25rem;
  font-weight: 700;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
  /* background-color: #c35b67; */
  background-color: #4A7355;
}

/* date flap */
ul li .date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;

  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

/* circle */
ul li .date::after {
  content: "";
  position: absolute;
  width: 1.6rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  /* border: 0.2rem solid #c35b67; */
  border-radius: 50%;
  top: 50%;

  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */
ul li .titleTime,
ul li .descr {
  background: var(--bgColor);
  position: relative;
  padding-inline: 1rem;
  font-family: 'Roboto';
  font-weight: 300;
}
ul li .titleTime {
  overflow: hidden;
  padding-block-start: 1rem;
  padding-block-end: 1rem;
  font-weight: 500;
  font-family: 'Bodoni Moda';
}
ul li .descr {
  padding-block-end: 1.5rem;
}

/* shadows */
ul li .titleTime::before,
ul li .descr::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}

/* .banner {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
} */

ul li .titleTime::before {
  bottom: calc(100% + 0.125rem);
}

ul li .descr::before {
  z-index: -1;
  bottom: 0.25rem;
}

@media (min-width: 40rem) {
  ul {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }
  ul::before {
    grid-column: 2;
  }
  ul li:nth-child(odd) {
    grid-column: 1;
  }
  ul li:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  ul li:nth-child(2) {
    grid-row: 2/4;
  }

  ul li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  ul li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  ul li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}

.credits {
  margin-top: 1rem;
  text-align: right;
}
.credits a {
  color: var(--color);
}

/* floating effect */
 
.floating {  
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }    
}

/* bounce effect */

.bounce-in-right {
  animation: bounce-in-right 2s ease 1;
}
@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

.bounce2 {
  animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

.gelatine {
  animation: gelatine 1.3s infinite;
}
@keyframes gelatine {
  from, to { transform: scale(1, 1); }
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  75% { transform: scale(0.95, 1.05); }
}